/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useField } from 'formik';
import styled from 'styled-components';
import FormError from './FormError';
import FormLabel from './FormLabel';
/*
 * Input fields
 *
 * Marketo types: ["string", "email", "phone", "url", "number"]
 * Standard type: ["text", "email", "tel", "url", "number"
 */

export const InputStyled = styled.input`
  background: ${(props) => props.mode === 'light' ? 'transparent' :  props.theme.colors.white};
  border: 1px solid ${(props) => props.mode === 'light' ? '#969696' : props.theme.forms.border};
  border-radius: ${(props) => props.theme.borderRadius.small};
  box-sizing: border-box;
  box-shadow: none;
  color: ${(props) => props.mode === 'light' ? '#FFFFFF' : props.theme.forms.text};
  font-size: 16px;
  height: 40px;
  margin: 0;
  padding: 8px 1px 8px 14px;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  width: 100%;

  /* Removes arrows from number fields
  Chrome, Safari, Edge, Opera */
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }

  &.form-control {
    :focus {
      background: ${(props) => props.mode === 'light' ? 'transparent' :  props.theme.colors.white};
      border: 1px solid ${(props) => props.theme.colors.blue_30};
      box-shadow: 0 0 0 1px ${(props) => props.theme.colors.blue_30};
      color: ${(props) => props.mode === 'light' ? '#FFFFFF' : props.theme.forms.text};
      outline: none;
    }
    ::-webkit-input-placeholder,
    :-moz-placeholder,
    ::placeholder,
    :-ms-input-placeholder {
      color: ${(props) => props.mode === 'light' ? '#FFFFFF' : props.theme.forms.text};
    }
    &.is-invalid {
      background-color: ${(props) => props.mode === 'light' ? 'transparent' : props.theme.colors.white};
      background-image: none;
      border: 1px solid ${(props) => props.theme.colors.red_error};
      &:focus {
        box-shadow: 0 0 0 1px ${(props) => props.theme.colors.red_error};
      }
    }
  }
`;

function FormText(props) {
  const [field, meta] = useField({
    ...props
  });
  useEffect(() => {
    const isEnterpriseForm = document.getElementById('form-1109');
    if (field.name === 'Email' && isEnterpriseForm) {
      const emailDomains =
        /^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(gmail|aol|outlook|yahoo|hotmail|icloud|inbox|mail|GMAIL|AOL|OUTLOOK|YAHOO|HOTMAIL|ICLOUD|INBOX|MAIL)\.com$/;
      const EmailField = document.querySelectorAll('[name="Email"]');
      const CompanyLabel = document.querySelectorAll('[for="Company"]');
      const toggledField = document.getElementById('CompanyReq');
      // toggle asterik on initial load and show/hide if element exists
      if (
        !toggledField &&
        EmailField[0].name === 'Email' &&
        emailDomains.test(field.value)
      ) {
        const asterik = document.createElement('span');
        asterik.setAttribute('id', 'CompanyReq');
        asterik.innerText = '*';
        CompanyLabel[0].append(asterik);
      } else if (
        toggledField &&
        EmailField[0].name === 'Email' &&
        !emailDomains.test(field.value)
      ) {
        toggledField.classList.add('d-none');
      } else if (
        toggledField &&
        EmailField[0].name === 'Email' &&
        emailDomains.test(field.value)
      ) {
        toggledField.classList.remove('d-none');
      }
    }
  }, [field.name, field.value]);
  const { name, mode, type, placeholder, label, required, VisibilityRule, isEmail } =
    props;
  return (
    <div
      id={VisibilityRule ? VisibilityRule.rules[0].subjectField : ''}
      className={
        name === 'honeypot' || VisibilityRule ? 'd-none form-row' : 'form-row'
      }
    >
      <div className="form-group col-12">
        {FormLabel(props)}
        <InputStyled
          name={name}
          mode={mode}
          type={type}
          title={placeholder || ''}
          label={label}
          required={!!required}
          placeholder={placeholder || ''}
          className={
            meta.touched && meta.error
              ? `form-control is-invalid ${
                  (VisibilityRule && VisibilityRule.rules[0].values[0]) || ''
                }`
              : `form-control ${
                  (VisibilityRule && VisibilityRule.rules[0].values[0]) || ''
                }`
          }
          /* eslint-disable react/jsx-props-no-spreading */
          {...field}
          {...props}
        />
      </div>
      <div className="col-12">
        {meta.touched && meta.error ? (
          <FormError className="error" mode={mode}  isEmail={isEmail}>
            {meta.error}
          </FormError>
        ) : null}
      </div>
    </div>
  );
}

export default FormText;
