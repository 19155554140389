/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
/* eslint-enable */

import styled from 'styled-components';
import FormError from './FormError';
import FormLabel from './FormLabel';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

/*
 * Dropdown field with optional hidden fields
 *
 * Marketo type: "picklist"
 * Standard type: "select"
 */

const SelectDropdown = styled.select`
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: 1px solid
    ${(props) =>
      props.mode === 'light' ? '#969696' : props.theme.forms.border};
  border-radius: ${(props) => props.theme.borderRadius.small} !important;
  color: ${(props) =>
    props.mode === 'light' ? '#FFFFFF' : props.theme.forms.text};
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  height: 40px;
  padding: 8px 1px 8px 14px;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  width: 100%;
  word-wrap: normal;
  &:hover {
    cursor: pointer;
  }
  &.form-control {
    margin-bottom: 0px;
    :focus {
      background: ${(props) =>
        props.mode === 'light' ? 'transparent' : props.theme.colors.white};
      border: 1px solid ${(props) => props.theme.colors.blue_30};
      box-shadow: 0 0 0 1px ${(props) => props.theme.colors.blue_30};
      color: ${(props) =>
        props.mode === 'light' ? '#FFFFFF' : props.theme.colors.black};
      outline: none;
    }
    ::-webkit-input-placeholder,
    :-moz-placeholder {
      color: ${(props) =>
        props.mode === 'light' ? '#FFFFFF' : props.theme.forms.text} !important;
    }
    &.is-invalid {
      background-color: ${(props) =>
        props.mode === 'light' ? 'transparent' : props.theme.colors.white};
      background-image: none;
      border: 1px solid ${(props) => props.theme.colors.red_error};

      &:focus {
        box-shadow: 0 0 0 1px ${(props) => props.theme.colors.red_error};
      }
    }
  }
`;
function FormDropdown(props) {
  const [field, meta] = useField({
    ...props
  });
  const {
    dropdownDefaultValue,
    inputlabel,
    isMultiSelect,
    name,
    placeholder,
    selectValues,
    VisibilityRule,
    type
  } = props;
  useEffect(() => {
    function isChildElementToParent(dropdowns, i, input, classValue) {
      return (
        dropdowns[i].querySelector(input) &&
        dropdowns[i].querySelector(input).classList.contains(classValue)
      );
    }

    function removeRequired(dropdowns, i, inputs) {
      const dropdownMenus = dropdowns[i].querySelectorAll(inputs);
      for (let index = 0; index < dropdownMenus.length; index += 1) {
        if (dropdownMenus[index].hasAttribute('required')) {
          dropdownMenus[index].removeAttribute('required');
          dropdownMenus[index].classList.add('d-none');
        }
      }
    }
    function addRequired(dropdowns, i, inputs) {
      const dropdownMenus = dropdowns[i].querySelectorAll(inputs);
      for (let index = 0; index < dropdownMenus.length; index += 1) {
        if (!dropdownMenus[index].hasAttribute('required')) {
          dropdownMenus[index].setAttribute('required', '');
          dropdownMenus[index].classList.remove('d-none');
        }
      }
    }
    const toggleHiddenFields = () => {
      const dropdowns = document.querySelectorAll(`div[id='${name}']`);

      for (let i = 0; i < dropdowns.length; i += 1) {
        if (
          field.value !== 'United States' &&
          field.value !== '' && [dropdowns[i] === 'Country']
        ) {
          dropdowns[i].classList.remove('d-none');
        } else {
          dropdowns[i].classList.add('d-none');
        }

        if (
          field.value === 'Current Student Leader' &&
          field.value !== '' && [dropdowns[i] === 'LeadSource']
        ) {
          dropdowns[i].classList.remove('d-none');
        } else {
          dropdowns[i].classList.add('d-none');
        }
        // enable fields under dropdown if value is "yes" or "true"
        if (
          isChildElementToParent(dropdowns, i, 'input', 'Yes') ||
          isChildElementToParent(dropdowns, i, 'textarea', 'Yes') ||
          isChildElementToParent(dropdowns, i, 'select', 'Yes') ||
          isChildElementToParent(
            dropdowns,
            i,
            'select',
            'Current Student Leader'
          )
        ) {
          if (field.value === 'Yes' && dropdowns[i].id === field.name)
            dropdowns[i].classList.remove('d-none');
          addRequired(dropdowns, i, 'input');
          addRequired(dropdowns, i, 'select');
          addRequired(dropdowns, i, 'textarea');
          if (field.value !== 'Yes' && dropdowns[i].id === field.name) {
            dropdowns[i].classList.add('d-none');
            removeRequired(dropdowns, i, 'input');
            removeRequired(dropdowns, i, 'select');
            removeRequired(dropdowns, i, 'textarea');
          }
        }
        // enable fields under dropdown if value chosen is "no" or "false"
        if (
          isChildElementToParent(dropdowns, i, 'input', 'No') ||
          isChildElementToParent(dropdowns, i, 'textarea', 'No') ||
          isChildElementToParent(dropdowns, i, 'select', 'No') ||
          isChildElementToParent(
            dropdowns,
            i,
            'select',
            'Current Student Leader'
          )
        ) {
          if (field.value === 'No' && dropdowns[i].id === field.name)
            dropdowns[i].classList.remove('d-none');
          addRequired(dropdowns, i, 'input');
          addRequired(dropdowns, i, 'select');
          addRequired(dropdowns, i, 'textarea');
          if (field.value !== 'No' && dropdowns[i].id === field.name) {
            dropdowns[i].classList.add('d-none');
            removeRequired(dropdowns, i, 'input');
            removeRequired(dropdowns, i, 'select');
            removeRequired(dropdowns, i, 'textarea');
          }
        }
      }
    };
    toggleHiddenFields();
  }, [name, field]);

  return (
    <div
      className="form-row"
      id={VisibilityRule ? VisibilityRule.rules[0].subjectField : ''}
    >
      <div className="form-group col-12">
        {FormLabel(props)}
        <SelectDropdown
          aria-label={inputlabel}
          className={
            meta.touched && meta.error
              ? `form-control is-invalid ${
                  (VisibilityRule && VisibilityRule.rules[0].values[0]) || ''
                }`
              : `form-control ${
                  (VisibilityRule && VisibilityRule.rules[0].values[0]) || ''
                }`
          }
          defaultDropdown={dropdownDefaultValue[0]}
          multiple={isMultiSelect || false}
          name={name}
          label={placeholder || inputlabel}
          title={placeholder || inputlabel}
          type={type}
          value={isMultiSelect ? [] : ''}
          /* eslint-disable react/jsx-props-no-spreading */
          {...field}
          {...props}
        >
          {selectValues.map((select) => (
            <option value={select.value} key={pmUuid()}>
              {select.label}
            </option>
          ))}
        </SelectDropdown>
      </div>
      <div className="col-12">
        {meta.touched && meta.error ? (
          <FormError className="error">{meta.error}</FormError>
        ) : null}
      </div>
    </div>
  );
}

FormDropdown.propTypes = {
  dropdownDefaultValue: PropTypes.string.isRequired,
  inputlabel: PropTypes.string.isRequired,
  isMultiSelect: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  selectValues: PropTypes.shape([]).isRequired,
  type: PropTypes.string.isRequired,
  VisibilityRule: PropTypes.shape({
    rules: []
  }).isRequired
};

export default FormDropdown;
