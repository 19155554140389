/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/function-component-definition */
/* eslint-disable prettier/prettier */
import React from 'react';
import styled from 'styled-components';

// Standard Form Container
const FormWrapper = styled.div`
  background-color: ${(props) =>
    props.mode === 'light' ? 'transparent' : props.theme.colors.white};
  border: 1px;
  border-radius: ${(props) => props.theme.borderRadius.medium};
  margin: 0;
  padding: 24px;
  position: relative;
  width: 100%;

  .form-row {
    margin-bottom: 0;
  }

  @media screen and (max-width:992px){
    padding: 24px 0;
  }
`;

const FormContainer = ({ ...props }) => (
    <FormWrapper {...props} />
);
export default FormContainer;
